
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  getCurrentInstance,
  ComponentInternalInstance,
} from "vue";
import pagaTable from "@/components/pagination-table/index";
import formSearch from "@/components/page-search/form";
import popupWindow from "./popupNew.vue";
import {
  FreightTemplatController_page,
  FreightTemplatController_del,
} from "@/api/mallManagement/freightManagement";
import { message } from "ant-design-vue";
const purchaseColumns = [
  {
    title: "名称",
    dataIndex: "name",
  },
  {
    title: "类型",
    dataIndex: "createdTime",
  },
  {
    title: "操作",
    dataIndex: "operation",
    slots: { customRender: "operation" },
  },
];
export default defineComponent({
  name: "questionType",
  components: {
    pagaTable,
    popupWindow,
    formSearch,
  },
  setup(props: any) {
    const { proxy } = getCurrentInstance() as ComponentInternalInstance;
    // const treeData = ref<TreeDataItem[]>([]);
    const data = reactive({
      purchaseColumns,
      selectedRowKeys: [],
      extraModel: {},
      formBtn: {
        colProps: { span: 16 },
        formItemProps: {
          wrapperCol: {
            span: 24,
          },
        },
      },
    });
    const onSelectChange = (selectedRowKeys: never[]) => {
      data.selectedRowKeys = selectedRowKeys;
    };
    const table = ref();
    const getDataApi = (params: any) => {
      return new Promise((reslove) => {
        const params_api = {
          currentPage: params.pageIndex,
          pageSize: params.pageSize,
        };
        FreightTemplatController_page(params_api).then((res) => {
          reslove(res);
        });
      });
    };
    const handleChange = (
      pagination: any,
      filter: any,
      sorter: any,
      { currentDataSource }: any
    ) => {
      // console.log(pagination, filter, sorter, currentDataSource);
    };
    const handleClick = (text: string, row: any) => {
      Title.value = "编辑运费模板";
      popupWindow.value.showModal(row);
    };
    const formSearch = ref();
    const popupWindow = ref();
    const Title = ref();
    const updatedTime = ref([]);
    const handleAdd = () => {
      Title.value = "新增运费模板";
      popupWindow.value.showModal();
    };
    const handleSearch = (formState: any) => {
      table.value.refresh();
    };
    const refresh = () => {
      table.value.refresh();
      data.selectedRowKeys = [];
    };

    const del = (val: any) => {
      FreightTemplatController_del({ id: val.freightTemplatId }).then((res) => {
        table.value.refresh();
        message.success("操作成功");
      });
    };
    const roleId = ref();
    return {
      del,
      refresh,
      ...toRefs(data),
      table,
      onSelectChange,
      getDataApi,
      handleChange,
      handleClick,
      formSearch,
      popupWindow,
      Title,
      handleAdd,
      handleSearch,
      updatedTime,
      // success
    };
  },
});
