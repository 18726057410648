import axios from "../../axios-config";
//运费模板
/**
 * 新增
 */
export function FreightTemplatController_post(params: any) {
  return axios.post("/api/back/mall/FreightTemplatController", { ...params });
}

/**
 * 修改
 */
export function FreightTemplatController_put(params: any) {
  return axios.put("/api/back/mall/FreightTemplatController", { ...params });
}

/**
 * 删除
 */
export function FreightTemplatController_del(params: any) {
  return axios.delete("/api/back/mall/FreightTemplatController", {
    params: {
      ...params
    }
  });
}
/**
 *  分页查询
 */
export function FreightTemplatController_page(params: any) {
  return axios.get("/api/back/mall/FreightTemplatController/page", {
    params: {
      ...params
    }
  });
}

/**
 * 查询全国省份
 */
export function DistrictController_find(params: any) {
  return axios.get("/api/back/system/DistrictController/find", {
    params: {
      ...params
    }
  });
}

