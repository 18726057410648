<template>
  <div>
    <a-modal width="800px" :title="Title" :visible="visible" :ok-button-props="{ danger: true }" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="cancel">
      <a-form ref="formRef" style="height: 600px; overflow-x: auto; padding: 10px" :label-col="{ style: { width: '100px' } }" :model="formState" :rules="rules">
        <a-form-item label="名称：" name="name">
          <a-input v-model:value="formState.name" style="width: 80%" />
        </a-form-item>
        <a-form-item label="模板类型：" prop="type">
          <a-radio-group v-model:value="formState.type" @change="onChange">
            <a-radio value="1"> 卖家包邮 </a-radio>
            <a-radio value="2"> 买家承担运费</a-radio>
          </a-radio-group>
        </a-form-item>
        <div v-if="formState.type == 2">
          <div v-for="(item, index) in formState.freightTemplatDistricts" style="border-top: 1px solid #eee; padding-top: 20px" :key="index">
            <PlusSquareOutlined style="float: right; cursor: pointer" v-if="index == 0" @click="handleAdd()" />
            <MinusSquareOutlined style="float: right; cursor: pointer" v-else @click="handleDel(index)" />
            <a-form-item label="不包邮省份" :name="['freightTemplatDistricts', index, 'provinceName']" :rules="{
                required: true,
                type: 'array',
                message: '不能为空',
                trigger: 'change',
              }">
              <a-select v-model:value="item.provinceName" style="width: 80%" mode="multiple" :options="areaList" allowClear>
              </a-select>
            </a-form-item>
            <a-form-item label="计算方式：" :name="['freightTemplatDistricts', index, 'chargeType']" :rules="{
                required: true,
                message: '不能为空',
                trigger: 'change',
              }">
              <a-radio-group v-model:value="item.chargeType" @change="onchargeType(index)">
                <a-radio value="1"> 按件数 </a-radio>
                <a-radio value="2"> 按重量</a-radio>
                <a-radio value="3"> 按体积</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-row v-if="item.chargeType == 1">
              <a-col :span="12">
                <a-form-item label="首件(个)：" :name="['freightTemplatDistricts', index, 'firstNum']" :rules="{
                    required: true,
                    type: 'number',
                    message: '不能为空',
                    trigger: 'change',
                  }">
                  <a-input-number v-model:value="item.firstNum" style="width: 80%" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="续件(个)：" :name="['freightTemplatDistricts', index, 'continueNum']" :rules="{
                    required: true,
                    type: 'number',
                    message: '不能为空',
                    trigger: 'change',
                  }">
                  <a-input-number v-model:value="item.continueNum" style="width: 80%" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row v-if="item.chargeType == 2">
              <a-col :span="12">
                <a-form-item label="首重(kg)：" :name="['freightTemplatDistricts', index, 'firstNum']" :rules="{
                    required: true,
                    type: 'number',
                    message: '不能为空',
                    trigger: 'change',
                  }">
                  <a-input-number v-model:value="item.firstNum" style="width: 80%" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="续重(kg)：" :name="['freightTemplatDistricts', index, 'continueNum']" :rules="{
                    required: true,
                    type: 'number',
                    message: '不能为空',
                    trigger: 'change',
                  }">
                  <a-input-number v-model:value="item.continueNum" style="width: 80%" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row v-if="item.chargeType == 3">
              <a-col :span="12">
                <a-form-item label="首体积(m²)：" :name="['freightTemplatDistricts', index, 'firstNum']" :rules="{
                    required: true,
                    type: 'number',
                    message: '不能为空',
                    trigger: 'change',
                  }">
                  <a-input-number v-model:value="item.firstNum" style="width: 80%" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="续体积(m²)：" :name="['freightTemplatDistricts', index, 'continueNum']" :rules="{
                    required: true,
                    type: 'number',
                    message: '不能为空',
                    trigger: 'change',
                  }">
                  <a-input-number v-model:value="item.continueNum" style="width: 80%" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row v-if="item.chargeType">
              <a-col :span="12">
                <a-form-item label="首运费(元)：" :name="['freightTemplatDistricts', index, 'firstFreight']" :rules="{
                    required: true,
                    type: 'number',
                    message: '不能为空',
                    trigger: 'change',
                  }">
                  <a-input-number v-model:value="item.firstFreight" style="width: 80%" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="续运费(元)：" :name="['freightTemplatDistricts', index, 'continueFreight']" :rules="{
                    required: true,
                    type: 'number',
                    message: '不能为空',
                    trigger: 'change',
                  }">
                  <a-input-number v-model:value="item.continueFreight" style="width: 80%" />
                </a-form-item>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import {
  DistrictController_find,
  FreightTemplatController_post,
  FreightTemplatController_put
} from '@/api/mallManagement/freightManagement'
const freightTemplatDistricts = {
  //省份
  provinceName: undefined,
  //计费类型
  chargeType: undefined,
  //	首件、首体积、首重量
  firstNum: undefined,
  //	首运费
  firstFreight: undefined,
  //续件、续体积、续重量
  continueNum: undefined,
  //续运费
  continueFreight: undefined
}
const form = {
  name: undefined,
  categoryId: undefined,
  deviceNum: undefined,
  type: undefined,
  freightTemplatDistricts: [{ ...freightTemplatDistricts }]
}

export default defineComponent({
  name: 'index',
  props: {
    Title: {
      type: String,
      required: false
    },
    FormData: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      visible: false,
      formState: {
        ...form
      },
      rules: {
        name: [{ required: true, message: '不能为空', trigger: 'change' }],
        type: [
          {
            required: true,
            message: '不能为空',
            trigger: 'blur'
          }
        ],
        deviceNum: [
          {
            required: true,
            type: 'number',
            message: '不能为空',
            trigger: 'blur'
          }
        ]
      },
      editor: false,
      areaList: [], // 地区
      oldList: [] // 备份地区
    }
  },
  components: {},
  created() {},
  methods: {
    cancel() {
      this.$refs.formRef.resetFields()
      this.formState = { ...form }
      this.editor = false
      this.visible = false
    },
    handleAdd() {
      this.formState.freightTemplatDistricts.push({
        ...freightTemplatDistricts
      })
    },
    handleDel(index) {
      this.formState.freightTemplatDistricts.splice(index, 1)
    },
    handleOk() {
      this.$refs.formRef.validate().then(() => {
        this.confirmLoading = true
        this.formState.freightTemplatDistricts.map((res) => {
          res.provinceName = res.provinceName.join()
        })
        if (!this.editor) {
          FreightTemplatController_post(this.formState).then((res) => {
            this.visible = false
            this.confirmLoading = false
            this.$message.success('操作成功')
            this.cancel()
            this.$parent.refresh()
          })
        } else {
          FreightTemplatController_put(this.formState).then((res) => {
            this.visible = false
            this.confirmLoading = false
            this.$message.success('操作成功')
            this.cancel()
            this.$parent.refresh()
          })
        }
      })
    },
    onChange() {
      if (this.formState.type == 1) {
        this.formState.freightTemplatDistricts = []
        this.formState.freightTemplatDistricts.push({
          ...freightTemplatDistricts
        })
      }
    },
    onchargeType(index) {
      const params = {
        //	首件、首体积、首重量
        firstNum: undefined,
        //	首运费
        firstFreight: undefined,
        //续件、续体积、续重量
        continueNum: undefined,
        //续运费
        continueFreight: undefined
      }
      this.formState.freightTemplatDistricts[index] = {
        ...this.formState.freightTemplatDistricts[index],
        ...params
      }
    },
    showModal(val) {
      this.visible = true
      DistrictController_find({ level: 0 }).then((res) => {
        this.areaList = res.data.map((res) => {
          return { value: res.value, label: res.name, disabled: false }
        })
        this.oldList = { ...this.areaList }
      })
      if (val) {
        this.editor = true
        this.formState = { ...form, ...val }
        this.formState.freightTemplatDistricts.map((res) => {
          res.provinceName = res.provinceName.split(',')
        })
      } else {
        this.editor = false
      }
    }
  }
})
</script>
<style lang="less">
.page-wrapper {
  padding: 20px 32px;
}

.radio {
  width: 100px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #e0e0e2;
}
</style>
